.explica {
    display: grid;
    grid-template-columns: 25vw 1fr;
    grid-template-rows: 12vh 1fr 12vh;
    z-index: 0;

    height: 100vh;
    max-height: 900px;

    @media (orientation: portrait) {
        grid-template-columns: 10vw 1fr 10vw;
        grid-template-rows: 12vh 1fr 25vh;
        height: auto;
        max-height: none;

        @media (min-width: 750px) {
            grid-template-rows: 12vh 1fr 40vh;
        }
    }

    h3 {
        font-family: var(--font-acme);
        font-size: 8rem;
        font-weight: 800;
        line-height: 8.5rem;

        @media (max-width: 500px) {
            font-size: 5rem;
            line-height: 5.5rem;
        }
    }

    h4 {
        font-family: var(--font-open);
        font-weight: 900;
        font-size: 1.5rem;
        text-transform: uppercase;
        margin-bottom: -2rem;

        @media (max-width: 500px) {
            font-size: 1.2rem;
            margin-bottom: -3rem;
        }
    }

    p {
        font-family: var(--font-open);
        font-size: 1.1rem;
        margin-bottom: 1rem;
    }

    .box {
        grid-column: 2;
        grid-row: 2;

        display: flex;
        flex-direction: column;
        gap: 4rem;
    }

    .intro {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        max-width: 70%;

        @media (orientation: portrait) {
            max-width: none;
        }
    }

    .newsletter {
        max-width: 50%;

        @media (orientation: portrait) {
            max-width: none;
        }

        h4 {
            margin: 0
        }

        input[type="email"] {
            width: 100%;
            border-bottom: 1px solid;
            margin: 2rem 0 1rem;
            font-size: 1.3rem;
        }

        input[type="submit"] {
            font-weight: 900;
            font-size: 1.3rem;
        }
    }


    &.oque {
        background-image: url("../image/fuzisbg.jpg");
        background-repeat: no-repeat;
        background-position: 130% 2rem;

        @media (orientation: portrait) {
            background-position: bottom center;
            background-size: 100%;
        }
    }

    &.quemsomos {
        background-image: url("../image/balas.jpg");
        background-repeat: no-repeat;
        background-position: 100% 7rem;

        .intro {
            max-width: 50%;

        }

        @media (orientation: portrait) {
            background-position: right 10%;
            background-size: 60%;
            margin-top: -5rem;

            .intro {
                max-width: none;
            }

            @media (min-width: 750px) {
                background-position: right 4%;
                grid-template-rows: 12vh 1fr 20vh;
            }
        }

        @media (orientation: landscape) {
            @media (max-width: 1300px) {
                background-position: 130% 7rem;
            }
        }
    }

    &.treinados {
        background-image: url("../image/cao.jpg");
        background-repeat: no-repeat;
        background-position: 100%;
        background-size: 45%;

        .intro {
            max-width: 70%;
        }

        @media (orientation: portrait) {
            grid-template-rows: 12vh 1fr 35vh;
            max-height: none;
            height: auto;

            background-position: bottom center;
            background-size: 80%;

            .intro {
                max-width: none;
            }

            @media (min-width: 750px) {
                grid-template-rows: 12vh 1fr 55vh;
            }
        }
    }
}

.sobre {
    position: relative;
    z-index: 0;
}