.reveal {
  opacity: 0;

  &.active {
    opacity: 1;

    &.fade-bottom {
      animation: fade-bottom 1s ease-in;
    }

    &.fade-left {
      animation: fade-left 1s ease-in;
    }

    &.fade-right {
      animation: fade-right 1s ease-in;
    }

    &.fade-top {
      animation: fade-top 1s ease-in;
    }
  }
}

@keyframes scale-up {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}

@keyframes fade-left {
  0% {
    transform: translateX(50px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fade-right {
  0% {
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fade-top {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-bottom {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.image {
  position: relative;

  &.olho {
    grid-column: 1/3;
    grid-row: 3/3;

    @media (orientation: portrait) {
      grid-column: 1/3;
      grid-row: 4;
      top: 10rem;
    }
  }

  &.soldado {
    grid-column: 4/4;
    grid-row: 1/3;
    display: flex;
    flex-direction: row-reverse;

    @media (orientation: portrait) {
      grid-column: 2/4;

      .ilustra {
        width: 70%;
      }
    }
  }

  &.mortos {
    grid-column: 4/6;
    grid-row: 3/4;
    display: flex;
    flex-direction: row-reverse;
    overflow: hidden;

    @media (orientation: portrait) {
      grid-column: 1/4;
      grid-row: 5;
      top: 10rem;
    }
  }

  &.fuzis {
    grid-column: 1/6;
    grid-row: 4/5;
    display: flex;
    overflow: hidden;

    @media (orientation: portrait) {
      grid-column: 1/4;
      grid-row: 5;
      top: -2rem;
    }
  }

  &.fuzis_a {
    display: flex;
    justify-content: flex-end;
  }

  &.varal {
    grid-column: 1/6;
    grid-row: 5/5;
    display: flex;
    overflow: hidden;

    @media (orientation: portrait) {
      grid-column: 1/4;
      grid-row: 6;
    }
  }

  &.operacao {
    grid-column: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &.balas {
    grid-column: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &.helicoptero {
    grid-column: 4/6;
    grid-row: 2/4;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (orientation: portrait) {
      grid-column: 1/12;
      grid-row: 1/3;
    }
  }

  &.blindado {
    grid-column: 1/4;
    grid-row: 4/5;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (orientation: portrait) {
      grid-column: 1/10;
      grid-row: 7;
    }
  }

  &.suspeitos {
    grid-column: 3/6;
    grid-row: 5/6;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (orientation: portrait) {
      grid-column: 1/12;
      grid-row: 9;
    }
  }

  &.soldados {
    grid-column: 3/6;
    grid-row: 3/4;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (orientation: portrait) {
      grid-column: 2/12;
      grid-row: 2;
    }
  }

  &.pipas {
    grid-column: 1/4;
    grid-row: 4/4;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (orientation: portrait) {
      grid-column: 1/12;
    }
  }

  &.casa {
    grid-column: 1/6;
    grid-row: 6/6;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (orientation: portrait) {
      grid-column: 1/12;
      grid-row: 10;
    }
  }
}

.ilustra {
  position: absolute;
}

.fuzil {
  rotate: -48deg;
  top: 26%;
  left: -10%;

  @media (orientation: portrait) {
    top: 75px;
    left: 10%;
    width: 90% !important;

    @media (min-width: 750px) {
      top: 150px;
    }
  }
}

.fuzil2 {
  top: 5%;
  right: -40%;
  max-width: none;
  width: 120%;

  @media (orientation: landscape) {
    @media (max-width: 1300px) {
      display: none;
    }
  }
}

.fuzil3 {
  top: 30%;
  left: -5%;
  width: 40%;
  transform: scaleX(-1);
  rotate: 20deg;
}

.fuzil4 {
  rotate: 30deg;
  top: 20%;
  right: -7%;
  width: 80%;
}

.fuzil5 {
  width: 100%;
  right: -400px;
  top: 60px;
}

.fuzil6 {
  width: 50%;
  rotate: 20deg;
  right: 0;
  top: 360px;
}

.fuzil7 {
  width: 75%;
//  right: -200px;
  top: 60px;
}

.fuzil8 {
  width: 35%;
  top: 0;
  rotate: 20deg;
  transform: scaleX(-1);
}

.camisas {
  bottom: 0;
  width: 100%;
}

.menino {
  bottom: 0;

  @media (orientation: portrait) {
    max-width: 50%;
  }
}

.blindado1 {
  bottom: 5rem;
  right: 0;

  @media (orientation: portrait) {
    display: none;
  }
}

.blindado2 {
  bottom: 0;
}

.suspeitos {
  bottom: 0;
  right: 0;
}

.soldados {
  bottom: 0;
  right: 0;
}

.casa {
  bottom: 0;
}
