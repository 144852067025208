:root {
  font-family: var(--font-open);
  font-size: 13px;

  @media (orientation: portrait) {
    @media (min-width: 750px) {
      font-size: 14px;
    }

    @media (min-width: 640px) {
      font-size: 13px;
    }

    @media (max-width: 640px) {
      font-size: 12px;
    }

    @media (max-width: 500px) {
      font-size: 11px;
    }
  }

  @media (orientation: landscape) {
    @media (min-width: 1600px) {
      font-size: 15px;
    }

    @media (max-width: 1300px) {
      font-size: 14px;
    }

    @media (max-width: 640px) {
      font-size: 11px;
    }

    @media (max-height: 700px) {
      font-size: 10.5px;
    }
  }
}

.highlight {
  font-weight: 900;
  color: var(--color-orange);
}

.title-acme {
  font-family: var(--font-acme);
  font-size: 8rem;
  font-weight: 800;
  line-height: 7.5rem;
  margin-bottom: 4rem;

  @media (max-width: 500px) {
    font-size: 6rem;
    line-height: 6rem;
  }
}

.title-marker {
  font-family: var(--font-marker);
  font-weight: 300;
  font-size: 6.5rem;
  line-height: 5.5rem;

  @media (max-width: 500px) {
    font-size: 4rem;
    line-height: 4rem;
  }
}

.title-quote {
  font-family: var(--font-acme);
  font-weight: 300;
  font-size: 3.5rem;
  line-height: 4.5rem;
  color: var(--color-green);

  &.single {
    font-size: 20rem;
  }
}

.title-open {
  font-family: var(--font-open);
  font-size: 1.5rem;
  text-transform: uppercase;
  margin-bottom: 2rem;
  color: var(--color-orange);
  font-weight: 900;

  .vilapinheiro & {
    color: var(--color-green);
  }

  .roseiral & {
    color: var(--color-blue);

    @media (orientation: portrait) {
      margin-bottom: 15rem;
    }
  }

  .toll {
    display: block;
    color: #FFF;
  }
}
