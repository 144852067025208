.top {
    position: relative;
    z-index: 10;



    .menu {
        font-weight: bold;
        padding: 2rem 6rem;
        text-transform: uppercase;
        width: 100%;
        justify-content: space-between;

        span {
            display: flex;
            gap: 3rem;
        }

    }

    .mobile {
        display: none;
    }

}

.home {
    .top {
        .menu {
            padding: 1.5rem 6rem;
        }
    }
}

.page {
    .top {
        &.fixed {
            position: fixed;
            width: 100%;
            z-index: 99;
        }
    }
}

@media (orientation: portrait) {
    .top.flex {
        justify-content: space-between;
        width: 100%;
        z-index: 999;

        .menu {
            padding: 1.1rem 2rem;
            justify-content: space-between;
        }
    }

    .home {
        .top {
            &.fixed {
                position: fixed;
                width: 100%;
                z-index: 99;
            }
            .menu {
                justify-content: flex-end;
                & > a {
                    display: none;
                }
            }
            .mobile {
                display: block;
            }
        }
    }
}
