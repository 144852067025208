.box {
  display: flex;
  flex-direction: column;
  z-index: 12;
}

.chacina {
  display: grid;
  grid-template-columns: 5vw 1fr 4vw 1fr 5vw;
  grid-template-rows: 6rem 40rem 35rem 10rem;
  z-index: 10;
  position: relative;

  color: var(--color-light);

  background-repeat: round;
  background-image: url("../image/fundo.jpg");
  background-attachment: fixed;

  p {
    font-family: var(--font-open);
    font-size: 1.3rem;
    line-height: 2.5rem;
    color: var(--color-white);
    margin-bottom: 2rem;

    @media (max-width: 500px) {
      font-size: 1.1rem;
      line-height: 1.5rem;
    }
  }

  &.galeria {
    grid-template-rows: 6rem 18rem 8rem 1fr 8rem;
    height: 100vh;

    @media (orientation: portrait) {
      grid-template-rows: 5rem 18rem 5rem 1fr 4rem;

      @media (min-width: 750px) {
        grid-template-rows: 5rem 30rem 10rem 1fr 4rem;
      }
    }

    .box:nth-child(1) {
      grid-column: 2/3;
      grid-row: 2;
    }

    .wrap {
      grid-column: 2/5;
      grid-row: 4;
      display: grid;
      grid-template-columns: 5vw 1fr 5vw;

      .ocorrencias {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        gap: 4rem;
        margin-top: 3rem;

        .ocorrencia {

          .img {
            background-image: url("../image/modura.jpg");
            padding: 1.5rem 1.5rem 0 1.5rem;
            width: max-content;
          }

          p {
            color: #000;
            font-size: 1.1rem;
            line-height: 2rem;
            margin: 0;

            .highlight {
              font-size: 1.1rem;
            }

            @media (orientation: portrait) {
              font-size: 1.2rem;
            }
          }

          .legenda {
            height: 60px;
            display: flex;
            font-weight: 900;
            justify-content: center;
            flex-direction: column;
          }
        }
      }

      .seta {
        @media (orientation: portrait) {
          display: none;
        }
      }

      .prev {
        display: flex;
        align-items: center;
      }

      .next {
        display: flex;
        align-items: center;

        img {
          transform: scaleX(-1);
        }
      }

      .gallery-cell {
        margin-right: 25px;
        overflow: hidden;
      }

      .gallery-cell img {
        display: block;
        height: 150px;
        margin: 0 auto;
      }

      .flickity-page-dots {
        display: flex;
        justify-content: center;
        gap: 0.7rem;

        .dot {
          background: #FFF;
          width: 0.8rem;
          height: 0.8rem;
          border-radius: 3px;

          &.is-selected {
            background: var(--color-orange);
          }
        }
      }
    }
  }

  &.jacarezinho {
    grid-template-rows: 6rem 40rem 40rem 50rem 95rem;
    
    @media (orientation: portrait) {
      grid-template-columns: 5vw 90vw 5vw;
      grid-template-rows: 6rem 25rem 32rem 53rem 30rem 45rem;

      h4 {
        margin-bottom: 16rem;
      }

      @media (min-width: 750px) {
        grid-template-rows: 26rem 25rem 32rem 80rem 30rem 65rem;
      }
    }

    @media (orientation: landscape) {
      @media (max-width: 1300px) {
        grid-template-rows: 6rem 50rem 40rem 35rem 85rem;
      }
    }

    .box:nth-child(1) {
      grid-column: 2/3;
      grid-row: 2;

      @media (orientation: portrait) {
        grid-column: 2;
        grid-row: 2;
      }
    }

    .box:nth-child(2) {
      grid-column: 4/5;
      grid-row: 3;
      justify-content: space-between;

      @media (orientation: portrait) {
        grid-column: 2;
        grid-row: 4;
      }

      .desc {
        width: 60%;

        @media (orientation: portrait) {
          width: auto;
        }
      }
    }

    .box:nth-child(3) {
      grid-column: 2/5;
      grid-row: 5;

      @media (orientation: portrait) {
        grid-column: 2;
        grid-row: 6;
      }

      .title-marker {
        margin-bottom: 3rem;
      }

      .desc {
        max-width: 65%;

        @media (orientation: portrait) {
          max-width: 70%;
        }
      }
    }
  }

  &.vilapinheiro {
    grid-template-rows: 6rem 46rem 30rem 40rem 65rem;
    grid-template-columns: 5vw 30vw 20vw 1fr 5vw;

    @media (orientation: portrait) {
      grid-template-columns: 5vw repeat(9, 10vw) 5vw;
      grid-template-rows: 45rem 15rem 20rem 1rem 1rem 30rem 20rem 35rem 28rem;

      @media (min-width: 750px) {
        grid-template-rows: 45rem 15rem 20rem 1rem 1rem 45rem 20rem 50rem 50rem;
      }
    }

    @media (orientation: landscape) {
      @media (max-width: 1300px) {
        grid-template-rows: 6rem 46rem 30rem 40rem 75rem;

        .title-marker {
          font-size: 5rem;
        }
      }
    }

    .box:nth-child(1) {
      grid-column: 2/3;
      grid-row: 2;

      @media (orientation: portrait) {
        grid-column: 3/11;

        .title-open {
          text-align: right;
        }

        .title-acme {
          font-size: 4.5rem;
          line-height: 4.5rem;
          margin-left: 32%;
          text-align: right;
        }

        .intro {
          text-align: right;
        }
      }
    }

    .box:nth-child(2) {
      grid-column: 2/3;
      grid-row: 3;
      justify-content: space-between;

      .desc {
        width: 60%;
      }

      @media (orientation: portrait) {
        grid-column: 5/11;
        grid-row: 4;

        .title-marker {
          text-align: right;
        }
      }
    }

    .box:nth-child(3) {
      grid-column: 4/5;
      grid-row: 4;
      display: flex;
      flex-direction: row;
      padding: 5rem;
      gap: 1rem;

      h3 {
        margin-top: -2rem;
      }

      .desc {
        margin-top: 2rem;
      }

      @media (orientation: portrait) {
        grid-column: 2/11;
        grid-row: 6;
        padding: 5rem 0;
      }
    }

    .box:nth-child(4) {
      grid-column: 2/3;
      grid-row: 5;
      justify-content: space-evenly;

      .desc {
        max-width: 75%;
      }

      @media (orientation: portrait) {
        grid-column: 2/10;
        grid-row: 8;

        .desc {
          max-width: 90%;
        }
      }
    }

    .saibamais {
      color: var(--color-green);
    }
  }

  &.roseiral {
    grid-template-rows: 6rem 30rem 35rem 60em 12rem 50rem;
    grid-template-columns: 5vw 20vw 20vw 1fr 5vw;

    @media (orientation: portrait) {
      grid-template-columns: 5vw repeat(9, 10vw) 5vw;
      grid-template-rows: 5rem 20rem 28rem 5rem 40rem 25rem 1rem 10rem 10rem 28rem;

      @media (min-width: 750px) {
        grid-template-rows: 30rem 10rem 35rem 5rem 70rem 30rem 1rem 15rem 10rem 45rem;
      }
    }

    @media (orientation: landscape) {
      @media (max-width: 1400px) {
        grid-template-rows: 6rem 30rem 45rem 60em 12rem 50rem;
      }

      @media (max-width: 1300px) {
        grid-template-rows: 6rem 30rem 45rem 60em 20rem 50rem;
      }
    }

    .box:nth-child(1) {
      grid-column: 2/4;
      grid-row: 2;

      @media (orientation: portrait) {
        grid-column: 2/11;
        grid-row: 2/4;
      }
    }

    .box:nth-child(2) {
      grid-column: 2/3;
      grid-row: 3;
      justify-content: space-between;

      .desc {
        width: 60%;
      }

      @media (orientation: portrait) {
        grid-column: 2/7;
        grid-row: 4;
      }
    }

    .box:nth-child(3) {
      grid-column: 4/5;
      grid-row: 4;
      display: flex;
      padding: 5rem 0;
      gap: 1rem;

      h3 {
        margin-top: 1rem;
      }

      .desc {
        margin: 2rem 3rem 0 8rem;
      }

      @media (orientation: portrait) {
        grid-column: 4/11;
        grid-row: 6;
        padding: 0;

        .desc {
          margin: 0;
        }
      }
    }

    .box:nth-child(4) {
      grid-column: 4;
      grid-row: 5;

      @media (orientation: portrait) {
        grid-column: 2/11;
        grid-row: 8;
      }
    }

    .box:nth-child(5) {
      grid-column: 4;
      grid-row: 6;
      flex-direction: row-reverse;

      .desc {
        max-width: 42%;
        margin-top: -4rem;
      }

      @media (orientation: portrait) {
        grid-column: 2/11;
        grid-row: 9;

        .desc {
          max-width: none;
          margin-top: 0;
        }
      }
    }

    .saibamais {
      color: var(--color-blue);
    }
  }

  .intro {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .saibamais {
    font-family: var(--font-open);
    text-transform: uppercase;
    margin-bottom: 1rem;
    color: var(--color-orange);
    font-weight: 900;
  }
}