.menu {
  display: flex;
  gap: 2rem;

  &.float {
    z-index: 1;
    position: fixed;
    top: 5rem;
    left: 5vw;
    border-left: 8px solid;
    padding-left: 50px;
    opacity: 1;
    border-image: url("../image/border.jpg") 8;
    opacity: 0;

    @media (orientation: portrait) {
      display: none;
    }
  }

  li {
    font-family: var(--font-acme);
    font-size: 1.5rem;
    margin-bottom: 2rem;
    max-width: 250px;

    &.active {
      color: var(--color-light-blue);
      list-style-image: url("../image/bullet.svg");
      list-style-position: outside;
      margin-left: -42px;
      padding-left: 42px;
    }
  }

  &.mobile {
    display: none;
  }
}

/**
 * Mobile menu
 */
@media (orientation: portrait) {
  .mobile {

    .container {
      max-width: 1050px;
      width: 90%;
      margin: auto;
    }

    .navbar {
      width: 100%;
    }

    .nav-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 62px;
    }

    .navbar .menu-items {
      display: flex;
      border-image: url("../image/border_invert.jpg") 8;
    }

    .navbar .nav-container li {
      list-style: none;
      font-family: var(--font-acme);
      max-width: 250px;
      margin-bottom: 2rem;
      font-size: 1.5rem;

      &.active {
        list-style-image: url("../image/bullet.svg");
        list-style-position: outside;
        margin-left: -42px;
        padding-left: 42px;

        a {
          color: var(--color-light-blue);
        }
      }
    }

    .navbar .nav-container a {
      text-decoration: none;
      color: #FFF;
      font-weight: 500;
      font-size: 2rem;
    }

    .navbar .nav-container a:hover {
      font-weight: bolder;
    }

    .nav-container {
      display: block;
      position: relative;
      height: 60px;
    }

    .nav-container .checkbox {
      position: absolute;
      display: block;
      height: 32px;
      width: 32px;
      top: 20px;
      left: 20px;
      z-index: 5;
      opacity: 0;
      cursor: pointer;
    }

    .nav-container .hamburger-lines {
      display: block;
      height: 26px;
      width: 32px;
      position: absolute;
      top: 17px;
      left: 20px;
      z-index: 2;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .nav-container .hamburger-lines .line {
      display: block;
      height: 4px;
      width: 100%;
      border-radius: 10px;
      background: #fff;
    }

    .nav-container .hamburger-lines .line1 {
      transform-origin: 0% 0%;
      transition: transform 0.4s ease-in-out;
    }

    .nav-container .hamburger-lines .line2 {
      transition: transform 0.2s ease-in-out;
    }

    .nav-container .hamburger-lines .line3 {
      transform-origin: 0% 100%;
      transition: transform 0.4s ease-in-out;
    }

    .navbar .menu-items {
      position: fixed;
      height: 93vh;
      width: 100%;
      transform: translate(-150%);
      display: flex;
      flex-direction: column;
      transition: transform 0.5s ease-in-out;
      background: #000;
      top: 60px;
      align-items: center;
      justify-content: center;
    }

    .navbar .menu-items li {
      margin-bottom: 1.2rem;
      font-size: 1.5rem;
      font-weight: 500;
    }

    .logo {
      position: absolute;
      top: 5px;
      right: 15px;
      font-size: 1.2rem;
      color: #0e2431;
    }

    .nav-container input[type="checkbox"]:checked ~ .menu-items {
      transform: translateX(0);
    }

    .nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line1 {
      transform: rotate(45deg);
    }

    .nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line2 {
      transform: scaleY(0);
    }

    .nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line3 {
      transform: rotate(-45deg);
    }

    .nav-container input[type="checkbox"]:checked ~ .logo {
      display: none;
    }
  }
}