.footer {
  display: grid;
  grid-template-columns: 5vw 1fr 5vw;
  grid-template-rows: 5% 1fr 5%;
  position: relative;
  z-index: 10;
  background-color: #FFFFFF;
  background-image: url('../image/cadeiras.jpg');
  background-repeat: no-repeat;
  background-position: 140% 100%;
  background-size: 60%;

  @media (orientation: portrait) {
    background-size: 100%;
    background-position: 140px 100%;
  }

  h4 {
    font-family: var(--font-open);
    font-weight: 900;
    font-size: 1.5rem;
    border-bottom: 2px solid #000;
    text-transform: uppercase;
  }

  .box {
    grid-column: 2/2;
    grid-row: 2/2;
  }

  .equipe {
    display: flex;
    flex-direction: column;

    .col {
      width: 350px;
    }
  }

}

.cols {
  display: flex;
  gap: 4rem;
  padding: 1.5rem 0 6rem;


  @media (orientation: portrait) {
    flex-wrap: wrap;
    gap: 0;
  }

  @media (orientation: landscape) {
    @media (max-width: 1300px) {
      gap: 3rem;
    }
  }

  .group {
    display: flex;
    flex-direction: column;

    padding: 1rem 0;

    .link {
      color: var(--color-orange);
      font-weight: bold;
    }
  }
}

.inner {
  display: grid;
  grid-template-columns: 20% 10% 1fr;
  min-height: 300px;

  .col {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  @media (orientation: portrait) {
    display: flex;
    flex-wrap: wrap;

    .realizacao {
      max-width: 50%;
    }
  }

  .apoio {
    grid-column: 3;

    .apoios {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 5rem;

      span {
        max-width: 230px;
      }

      @media (orientation: portrait) {
        flex-wrap: wrap;

        span {
          max-width: 40%;
        }
      }
    }
  }
}



.colophon {
  border-top: 4px solid #000;
  padding-top: 2rem;
  position: relative;
  min-height: 510px;
  max-width: 70vw;
  overflow-x: hidden;
  font-size: 1.2rem;

  @media (orientation: portrait) {
    max-width: none;

    @media (min-width: 750px) {
      min-height: 740px;
    }
  }

  .cadeiras {
    position: absolute;
    bottom: 0;
    right: -150px;
  }

  .cols {
    @media (orientation: portrait) {
      gap: 2rem;
      flex-direction: column;
    }
  }

  .mail {
    font-weight: bold;
  }

  .news {
    text-decoration: underline;
  }

  .siga {
    margin-bottom: 5rem;

    @media (orientation: portrait) {
      margin-bottom: 2rem;
    }
  }

  .apps {
    display: flex;
    align-items: center;
    gap: 2rem;

    .app,
    .app img {
      width: 15vw;
      max-width: 150px;

      &.apple {
        max-height: 60px;
      }

      &.google {

      }
    }

    @media (orientation: portrait) {
      gap: 3.5rem;

      .app img {
        width: 25vw;
      }
    }

  }
}
