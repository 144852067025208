:root {
    --font-acme: "acme-gothic-compressed", sans-serif;
    --font-marker: "marker-aid", sans-serif;
    --font-open: "open-sans", sans-serif;
    --font-open-condensed: "open-sans-condensed", sans-serif;

    --color-white: #FFFFFF;
    --color-black: #000000;
    --color-light: #EAE4C5;
    --color-orange: #FE5F18;
    --color-green: #04D78C;
    --color-dark-green: #4F6550;
    --color-blue: #0070F3;
    --color-light-blue: #004EFF;

    --size-mobile: max-width: 500px;

    * {
        /* Pause the animation */
        animation-play-state: paused;
        /* Bind the animation to scroll */
        animation-delay: calc(var(--scroll) * -1s);
        /* These last 2 properites clean up overshoot weirdness */
        animation-iteration-count: 1;
        animation-fill-mode: both;
    }
}

p {
    font-family: var(--font-open);
}


